body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.menu {
  width: 300px;
}

.menu .logo {
  width: 290px;
  margin: 5px;
}

.main-container {
  background: #f9f9f9;
  min-height: calc(100vh - 20px);
  min-width: calc(100vw - 460px);
  padding: 20px 30px 0 30px;
}

.main-wrapper-screen {
  display: flex;
  flex-direction: row;
}

.top-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.top-section .content {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.top-section .content .text {
  flex: 40%;
}

.top-section .content .image {
  flex: 40%;
  min-width: 300px;
}

.top-section .content .image img {
  width: 100%;
}

.opportunity-container {
  background-color: #f97401;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;
  color: #fff;
}

.opportunity-text {
  flex: 0 40%;
  min-width: 400px;
}

.opportunity-image {
  flex: 0 40%;
  min-width: 400px;
}

.opportunity-image img {
  width: 100%;
}

.cover {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  background-image: url('./assets/image/home.jpg'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  color: white;
  font-size: 2.5em;
  text-align: center;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* padding: 20px; */
  font-size: 50px;
  transform: translateY(-40px);
  font-weight: 700;
  /* background-color: rgba(0, 0, 0, 0.7); Optional background for text readability */
}

.cover-logo {
  position: absolute;
  right: 0;
  bottom: 20px;
  height: 80px;
}

.red-line {
  width: 100px; /* Adjust as needed */
  height: 5px;
  position: absolute;
  bottom: 60px;
  right: 240px;
  transform: rotate(135deg);
  background-color: red;
  margin-bottom: 20px;
}